// @flow
import { handleActions } from 'redux-actions'
import { set } from 'lodash'
import { photoActionTypes } from './actions'

import type { fetchAlbumsStateT, fetchAlbumsActionT, setLoadingStatusActionT } from './types'
import history from '../../history'
import { ROUTES } from '../../constants'

export const photosInitialState = (): fetchAlbumsStateT => ({
  albums: [],
  album: { active: false },
  loadedPhotos: [],
  loadPhotosActive: false,
  // photos: [
  //   {
  //     id: 0,
  //     src: 'https://www.simple-react-lightbox.dev/docs/gallery/unsplash18.jpg',
  //     thumbnail: 'https://www.simple-react-lightbox.dev/docs/gallery/thumbnails/unsplash18.jpg',
  //     caption: '',
  //     // width: 1920,
  //     // height: 'auto',
  //   },
  // ],
  photos: [],
  loadingStatus: null,
})

const reduceFetchAlbumsSuccess = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    albums: data,
  }
}
const reduceFetchAlbumSuccess = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    album: data,
  }
}

const reduceSetLoadingStatus = (state: fetchAlbumsStateT, action: setLoadingStatusActionT) => {
  return {
    ...state,
    loadingStatus: action.payload,
  }
}

const reducePhotosLoaded = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    loadedPhotos: action.payload,
  }
}

const reduceDeletePhotoLoaded = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    loadedPhotos: state.loadedPhotos.filter(file => file.name !== action.payload),
  }
}

const reduceInsertPhotoSucess = (state: FilesStateT, action: SendFilesActionT) => {
  let loadPhotos

  if (state.loadedPhotos.filter(file => file.name !== action.payload).length === 0) {
    loadPhotos = false
    window.location.reload()
  } else {
    loadPhotos = true
  }

  return {
    ...state,
    // photos: [...state.photos, data.data],
    loadedPhotos: state.loadedPhotos.filter(file => file.name !== action.payload),
    loadPhotosActive: loadPhotos,
  }
}

const reduceStartPhotoInsert = (state: FilesStateT, action: SendFilesActionT) => {
  return {
    ...state,
    loadPhotosActive: true,
  }
}

const reduceFetchPhotosLoaded = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  const { data } = action.payload
  return {
    ...state,
    photos: data,
  }
}

const reduceInsertAlbumSucess = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  const { data } = action.payload
  history.push(`${ROUTES.ADMIN_PHOTOALBUM}${data.data.id}`)
  return {
    ...state,
    album: data.data,
  }
}

const reduceUpdatePriorityAlbumSuccess = (state: NewsStateT, action: setNewsActionT) => {
  // console.log(state)

  // console.log(set(state, 'albums[1].active', true))
  const { data } = action.payload

  // console.log('reduceUpdatePriorityAlbumSuccess')
  // console.log(action.payload)
  // console.log(data)

  const index = state.albums.findIndex(element => element.id === data.id)
  const index2 = state.albums.findIndex(element => element.id === data.id2)

  return {
    ...set(state, `albums[${index}].priority`, data.priority),
    ...set(state, `albums[${index2}].priority`, data.priority2),
  }
}

const reduceDeleteAlbumSuccess = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  const { id } = action.payload
  history.push(`${ROUTES.ADMIN_PHOTOGALLERY}`)
  return {
    ...state,
    albums: state.albums.filter(album => album.id !== id),
  }
}

const reduceUnmountAlbum = (state: fetchAlbumsStateT, action: fetchAlbumsActionT) => {
  return {
    ...state,
    photos: [],
  }
}

export const photosReducer = handleActions<fetchAlbumsStateT, fetchAlbumsActionT>(
  {
    [photoActionTypes.FETCH_ALBUMS_SUCCESS]: reduceFetchAlbumsSuccess,
    [photoActionTypes.FETCH_ALBUM_SUCCESS]: reduceFetchAlbumSuccess,
    [photoActionTypes.FETCH_ALBUMS_LOADING_STATUS]: reduceSetLoadingStatus,
    [photoActionTypes.INSERT_PHOTO_SUCCESS]: reduceInsertPhotoSucess,
    [photoActionTypes.INSERT_ALBUM_SUCCESS]: reduceInsertAlbumSucess,
    [photoActionTypes.PHOTOS_LOADED]: reducePhotosLoaded,
    [photoActionTypes.DELETE_LOADED_PHOTO]: reduceDeletePhotoLoaded,
    [photoActionTypes.FETCH_PHOTOS_SUCCESS]: reduceFetchPhotosLoaded,
    [photoActionTypes.UPDATE_PRIORITY_ALBUM_SUCCESS]: reduceUpdatePriorityAlbumSuccess,
    [photoActionTypes.DELETE_ALBUM_SUCCESS]: reduceDeleteAlbumSuccess,
    [photoActionTypes.START_PHOTO_INSERT]: reduceStartPhotoInsert,
    [photoActionTypes.UNMOUNT_ALBUM]: reduceUnmountAlbum,
  },
  photosInitialState()
)
