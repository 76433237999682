// import { container } from "assets/jss/material-kit-react";
// import { container, primaryColor } from '../material-kit-react'
import { primaryColor } from '../material-kit-react'

const completedStyle = theme => ({
  // const completedStyle = {

  // const container = {
  //   ...conatinerFluid,
  //   "@media (min-width: 576px)": {
  //     maxWidth: "540px"
  //   },
  //   "@media (min-width: 768px)": {
  //     maxWidth: "720px"
  //   },
  //   "@media (min-width: 992px)": {
  //     maxWidth: "960px"
  //   },
  //   "@media (min-width: 1200px)": {
  //     maxWidth: "1140px"
  //   }
  // };

  /// /////////////////////////////////////////////////////////////////////////////////////////
  /// ///////////////////////////////////////////
  // obecne
  raisedMain: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
    // margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    paddingBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      margin: '-60px 30px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-60px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-450px',
    },
  },
  raisedMainAdmin: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
    // margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    paddingBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      margin: '-60px 30px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-60px',
    },
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: '-450px',
    // },
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    '@media (min-width: 576px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '720px',
    },
    '@media (min-width: 992px)': {
      maxWidth: '960px',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '1140px',
    },
  },
  // sarHeaderImage
  brand: {
    color: '#FFFFFF',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-250px',
    },
  },
  parallax: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: '4.2rem',
    fontWeight: '600',
    display: 'inline-block',
    position: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '2.2rem',
    //   fontWeight: '400',
    //   // marginTop: '-100px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '1.7rem',
    //   fontWeight: '300',
    //   // marginTop: '-100px',
    // },
    '@media (max-width: 650px)': {
      fontSize: '2.2rem',
      fontWeight: '400',
    },
    '@media (max-width: 450px)': {
      fontSize: '1.7rem',
      fontWeight: '300',
    },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '2.2rem',
    //   fontWeight: '400',
    //   // marginTop: '-100px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '1.7rem',
    //   fontWeight: '300',
    //   // marginTop: '-100px',
    // },
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '0px',
    [theme.breakpoints.down('xs')]: {
      margin: '-10px 0 0',
      fontSize: '1rem',
      // marginTop: '-100px',
    },
  },

  // subtitle
  defaultFontStyle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
    fontSize: '14px',
  },
  mutedText: {
    color: '#777',
  },
  /// ///////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////

  /// /////////////////////////////////////////////////////////////////////////////////////////
  /// ///////////////////////////////////////////

  // header
  // defaultFont: {
  //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  //   fontWeight: '300',
  //   lineHeight: '1.5em',
  // },
  appBar: {
    paddingRight: '25px',
    paddingLeft: '25px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
  },
  headerList: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
    fontSize: '14px',
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
  },
  headerListItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    '&:hover,&:focus': {
      color: 'white',
      // background: 'rgba(200, 200, 200, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:after': {
        width: 'calc(100% - 30px)',
        content: '""',
        display: 'block',
        height: '1px',
        marginLeft: '15px',
        backgroundColor: '#e5e5e5',
      },
    },
  },
  socialIcons: {
    position: 'relative',
    fontSize: '20px !important',
    marginRight: '4px',
  },
  /// ///////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////

  /// /////////////////////////////////////////////////////////////////////////////////////////
  /// ///////////////////////////////////////////

  adminNewsImgIcon: {
    marginTop: '6px',
    marginRight: '8px',
  },
  adminNewsActive: {
    marginTop: '8px',
    float: 'right',
  },

  /// ///////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////

  section: {
    padding: '70px 0',
  },
  center: {
    textAlign: 'center !important',
  },
  right: {
    float: 'right',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  imgBox: {
    padding: '0.5rem',
  },
  inputStyle: {
    marginBottom: '10px',
  },
  margin8Top: {
    marginTop: '8px',
  },
  margin10Top: {
    marginTop: '10px',
  },
  margin15Top: {
    marginTop: '15px',
  },

  marginRight: {
    marginRight: '5px',
  },

  margin10Left: {
    marginLeft: '10px',
  },
  side10Padding: {
    padding: '0 10px 0 10px',
  },

  /// /////////////////////////////////////////////////////////////////////////////////////////
  /// ///////////////////////////////////////////
  // MAIN

  newsList: {
    marginTop: '12px',
  },
  newsItem: {
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },

  adminNewsItemPrioButtons: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80px',
  },

  adminNewsItemMargin: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '50px',
    },
  },

  /// ///////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////////////////////////////

  // hover - zoom dalsi priklady na:  https://w3bits.com/css-image-hover-zoom/
  imgCrop: {
    maxHeight: '250px',
    overflow: 'hidden',
  },
  imgZoom: {
    // transformOrigin: '50% 65%',
    // transition: 'transform .5s, visibility .5s ease-in',
    transformOrigin: '0 0 0 0',
    transition: 'transform 5s, filter 3s ease-in-out',
    // filter: 'brightness(150%)',
    '&:hover': {
      // transform: 'scale(1.5)',
      filter: 'brightness(80%)',
      transform: 'scale(2)',
    },
  },
  imgRounded: {
    borderRadius: '6px !important',
    // marginLeft: "30px",
    // marginRight: "30px"
  },

  // imagesStyles
  imgFluid: {
    maxWidth: '100%',
    height: 'auto',
    float: 'left',
  },
  imgShadow: {
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  imgRoundedCircle: {
    borderRadius: '50% !important',
  },
  imgRaised: {
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  imgGallery: {
    width: '100%',
    marginBottom: '2.142rem',
  },
  imgCardTop: {
    width: '100%',
    borderTopLeftRadius: 'calc(.25rem - 1px)',
    borderTopRightRadius: 'calc(.25rem - 1px)',
  },
  imgCardBottom: {
    width: '100%',
    borderBottomLeftRadius: 'calc(.25rem - 1px)',
    borderBottomRightRadius: 'calc(.25rem - 1px)',
  },
  imgCard: {
    width: '100%',
    borderRadius: 'calc(.25rem - 1px)',
  },
  imgCardOverlay: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '1.25rem',
  },
  overlay: {
    position: 'absolute',
    // top: '100%',
    // right: '0',
    bottom: '-10px',
    // left: '0',
    // background: 'rgb(0, 0, 0)',
    background: 'rgba(0, 0, 0, 0.5)',
    color: '#f1f1f1',
    // textShadow: '-2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000',
    textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    width: '100%',
    // transition: '.5s ease',
    // color: 'white',
    fontSize: '24px',
    // padding: '20px',
    textAlign: 'center',
  },
  imgContainer: {
    position: 'relative',
    // width: '50%',
    // maxWidth: '300px'
  },
  linkButton: {
    color: '#9c27b0',
    fontSize: '1.5625rem',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    margin: '0',
    padding: '0',
    // fontFamily: "Roboto", "Helvetica", "Arial", sans-serif;
    fontFamily: ['Roboto', 'Helvetice', 'Arial'],
    fontWeight: '300',
    lineHeight: '1.5em',
  },

  // imgDiv: {
  //   display: 'block',
  //   width: '100%',
  //   height: 'auto',
  // },
  // footer
  footer: {
    padding: '0.9375rem 0',
    textAlign: 'center',
    display: 'flex',
    zIndex: '2',
    position: 'relative',
  },
  a: {
    color: primaryColor,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  left: {
    float: 'left!important',
    display: 'block',
  },
  // container,
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },
  footerRight: {
    padding: '15px 0',
    margin: '0',
    float: 'right!important',
  },

  // admin photogallery album
  albumButtons: {
    width: '30px',
    marginTop: '10px',
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
  },

  // adminActions
  paper: {
    backgroundColor: 'white',
    position: 'absolute',
    width: 400,
    border: '2px solid #000',
    padding: '0 25px 10px 25px',
  },
  root: {
    margin: 'auto',
  },
  modalStyle: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  actionsInputStyle: {
    marginTop: '10px',
    marginRight: '10px',
    minWidth: '250px',
  },
  actionsEditInput: {
    marginLeft: '5px',
    marginTop: '10px',
    paddingTop: '17px',
    paddingBottom: '17px',
    fontSize: '20px',
  },
  actionsTextFieldEdit: {
    width: '100%',
    marginRight: '10px',
  },
  // adminParticipants
  modalParticipant: {
    backgroundColor: 'white',
    position: 'absolute',
    width: 500,
    border: '2px solid #000',
    padding: '0 25px 10px 25px',
  },
  participantDetail: {
    padding: '16px',
    display: 'block',
    width: '100%',
  },
})

export default completedStyle
