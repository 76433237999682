// @flow
import { defineMessages } from 'react-intl'

export default defineMessages({
  sar: {
    id: 'app.sar',
    defaultMessage: 'Blue Kangaroo',
  },
  blueKangaroo: {
    id: 'app.blueKangaroo',
    defaultMessage: 'Blue Kangaroo',
  },
  mail: {
    id: 'app.mail',
    defaultMessage: 'info@studioar.cz',
  },
  mailBK: {
    id: 'app.mailBK',
    defaultMessage: 'bluekangaroo@bluekangaroo.info',
  },
  www: {
    id: 'app.www',
    defaultMessage: 'www.studioar.cz',
  },
  wwwBK: {
    id: 'app.wwwBK',
    defaultMessage: 'www.bluekangaroo.info',
  },
  studio: {
    id: 'app.studio',
    defaultMessage: 'Studio',
  },
  vaclavMachac: {
    id: 'app.vaclavMachac',
    defaultMessage: 'Mgr. Vaclav Machac',
  },
  address: {
    id: 'app.address',
    defaultMessage: 'Address',
  },
  cernilov: {
    id: 'app.cernilov',
    defaultMessage: 'Černilov 305, 503 43 Černilov',
  },
  phone: {
    id: 'app.address',
    defaultMessage: 'Phone',
  },
  phone1: {
    id: 'app.phone1',
    defaultMessage: '777 006 005',
  },
  phone2: {
    id: 'app.phone2',
    defaultMessage: '777 004 003',
  },
  filter: {
    id: 'app.filter',
    defaultMessage: 'Filter...',
  },
  yes: {
    id: 'app.yes',
    defaultMessage: 'yes',
  },
  no: {
    id: 'app.no',
    defaultMessage: 'no',
  },
  save: {
    id: 'app.save',
    defaultMessage: 'save',
  },
  archive: {
    id: 'app.archive',
    defaultMessage: 'archive',
  },
  send: {
    id: 'app.send',
    defaultMessage: 'save',
  },
  active: {
    id: 'app.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'app.inactive',
    defaultMessage: 'inactive',
  },
  selected: {
    id: 'app.selected',
    defaultMessage: 'Selected',
  },
  others: {
    id: 'app.others',
    defaultMessage: 'Others',
  },
  inactiveActionList: {
    id: 'app.inactiveActionList',
    defaultMessage: 'inactive Action List',
  },
  actionList: {
    id: 'app.actionList',
    defaultMessage: 'Action List',
  },
  chooseActionList: {
    id: 'app.chooseActionList',
    defaultMessage: 'chooseActionList',
  },
  appLogin: {
    id: 'app.login',
    defaultMessage: 'Login',
  },
  back: {
    id: 'app.back',
    defaultMessage: 'back',
  },
  required: {
    id: 'app.required',
    defaultMessage: 'required',
  },
  urlCopied: {
    id: 'app.urlCopied',
    defaultMessage: 'url copied',
  },
  notShared: {
    id: 'app.notShared',
    defaultMessage: 'not shared',
  },
  otherInfo: {
    id: 'app.otherInfo',
    defaultMessage: 'next info',
  },
  rowsPerPage: {
    id: 'app.rowsPerPage',
    defaultMessage: 'rowsPerPage',
  },
  separator: {
    id: 'app.separator',
    defaultMessage: 'from',
  },
  all: {
    id: 'app.all',
    defaultMessage: 'All',
  },
  edit: {
    id: 'app.edit',
    defaultMessage: 'Edit',
  },
  downloadExcel: {
    id: 'app.downloadExcel',
    defaultMessage: 'downloadExcel',
  },
  newNews: {
    id: 'admin.newNews',
    defaultMessage: 'Create new News',
  },
  validateEmail: {
    id: 'validate.email',
    defaultMessage: 'not valid mail',
  },
  validatePhone: {
    id: 'validate.phone',
    defaultMessage: 'not valid phone',
  },
  signout: {
    id: 'app.signout',
    defaultMessage: 'signout',
  },
  adminMenu: {
    id: 'app.adminMenu',
    defaultMessage: 'Admin Menu',
  },
  menu: {
    id: 'app.menu',
    defaultMessage: 'Menu',
  },
  successfullySent: {
    id: 'app.successfullySent',
    defaultMessage: 'successfully sent',
  },
  addFile: {
    id: 'app.addFile',
    defaultMessage: 'add file',
  },
  googleDrive: {
    id: 'app.googleDrive',
    defaultMessage: 'Google Drive',
  },
  title: {
    id: 'app.title',
    defaultMessage: 'Title',
  },
  notFound: {
    id: 'app.notFound',
    defaultMessage: 'Not found',
  },
  // headers
  reservation: {
    id: 'headers.reservation',
    defaultMessage: 'Reservation',
  },
  // links
  adminSection: {
    id: 'links.adminSection',
    defaultMessage: 'Admin Section',
  },
  adminNews: {
    id: 'links.adminNews',
    defaultMessage: 'Admin News',
  },
  news: {
    id: 'links.news',
    defaultMessage: 'news',
  },
  adminParticipants: {
    id: 'links.adminParticipants',
    defaultMessage: 'Participants',
  },
  mainPage: {
    id: 'links.mainPage',
    defaultMessage: 'Main page',
  },
  photogallery: {
    id: 'links.photogallery',
    defaultMessage: 'Photogallery',
  },
  adminPhotogallery: {
    id: 'links.adminPhotogallery',
    defaultMessage: 'Admin Photogallery',
  },
  adminActions: {
    id: 'links.adminActions',
    defaultMessage: 'Admin Actions',
  },
  conditionsPage: {
    id: 'links.conditions',
    defaultMessage: 'Conditions',
  },
  contactsPage: {
    id: 'links.contacts',
    defaultMessage: 'Contacts',
  },
  loginPage: {
    id: 'links.login',
    defaultMessage: 'Login',
  },
  faqSkiPage: {
    id: 'links.faqSki',
    defaultMessage: 'FaQ Ski',
  },
  faqCampPage: {
    id: 'links.faqCamp',
    defaultMessage: 'FaQ SUMMER CAMP',
  },
  faqBikePage: {
    id: 'links.faqBike',
    defaultMessage: 'FaQ Bike',
  },
  createNewAction: {
    id: 'actions.createNewAction',
    defaultMessage: 'createNewAction',
  },
  inactiveProgramList: {
    id: 'actions.inactiveProgramList',
    defaultMessage: 'inactiveProgramList',
  },
  createNewProgram: {
    id: 'actions.createNewProgram',
    defaultMessage: 'createNewProgram',
  },
  inactiveTransportList: {
    id: 'actions.inactiveTransportList',
    defaultMessage: 'inactiveTransportList',
  },
  createNewTransport: {
    id: 'actions.createNewTransport',
    defaultMessage: 'createNewTransport',
  },
  newsSubtitle: {
    id: 'news.subtitle',
    defaultMessage: 'Subtitle',
  },
  newsImage: {
    id: 'news.image',
    defaultMessage: 'Image',
  },
  newsChooseImage: {
    id: 'news.chooseImage',
    defaultMessage: 'choose image',
  },
  newsDeleteNews: {
    id: 'news.deleteNews',
    defaultMessage: 'Delete news',
  },
  newsConfirmDeleteNews: {
    id: 'news.confirmDeleteNews',
    defaultMessage: 'Delete news?',
  },
  newsConfirmDeleteNewsLong: {
    id: 'news.confirmDeleteNewsLong',
    defaultMessage: 'Delete news?',
  },

  twitterURL: {
    id: 'menu.twitterURL',
    defaultMessage: 'https://twitter.com/BlueKangarooSAR',
  },
  twitterLabel: {
    id: 'menu.twitterLabel',
    defaultMessage: 'Twitter',
  },
  fbURL: {
    id: 'menu.fbURL',
    defaultMessage: 'https://www.facebook.com/StudioAktivniRekreace/',
  },
  fbLabel: {
    id: 'menu.fbLabel',
    defaultMessage: 'Facebook',
  },
  instaURL: {
    id: 'menu.instaURL',
    defaultMessage: 'https://www.instagram.com/bluekangaroo_sar/',
  },
  instaLabel: {
    id: 'menu.instaLabel',
    defaultMessage: 'Instagram',
  },
  serverError: {
    id: 'toast.serverError',
    defaultMessage: 'Server error',
  },
  photoChoosePhoto: {
    id: 'photo.choosePhoto',
    defaultMessage: 'Choose photo',
  },
  photoPerfectsize: {
    id: 'photo.perfectSize',
    defaultMessage: 'perfectSize...',
  },
  photoConfirmDeleteAlbum: {
    id: 'photo.confirmDeleteAlbum',
    defaultMessage: 'confirmDeleteAlbum',
  },
  photoConfirmDeleteAlbumLong: {
    id: 'photo.confirmDeleteAlbumLong',
    defaultMessage: 'confirmDeleteAlbum?',
  },
  registrationAction: {
    id: 'registration.action',
    defaultMessage: 'Action',
  },
  registrationState: {
    id: 'registration.state',
    defaultMessage: 'State',
  },
  registrationChangeState: {
    id: 'registration.changeState',
    defaultMessage: 'changeState',
  },
  registrationProgram: {
    id: 'registration.program',
    defaultMessage: 'Program',
  },
  registrationPrograms: {
    id: 'registration.programs',
    defaultMessage: 'Programs',
  },
  registrationTransport: {
    id: 'registration.transport',
    defaultMessage: 'Transport',
  },
  registrationDeparture: {
    id: 'registration.departure',
    defaultMessage: 'Departure',
  },
  registrationArrival: {
    id: 'registration.arrival',
    defaultMessage: 'Arrival',
  },
  registrationParticipant: {
    id: 'registration.participant',
    defaultMessage: 'Participant',
  },
  registrationName: {
    id: 'registration.name',
    defaultMessage: 'Name',
  },
  registrationSurname: {
    id: 'registration.surname',
    defaultMessage: 'Surname',
  },
  registrationBirthnumber: {
    id: 'registration.birthnumber',
    defaultMessage: 'Birthnumber',
  },
  registrationAddress: {
    id: 'registration.address',
    defaultMessage: 'Address',
  },
  registrationStreet: {
    id: 'registration.street',
    defaultMessage: 'Street',
  },
  registrationHouseNumber: {
    id: 'registration.houseNumber',
    defaultMessage: 'House number',
  },
  registrationTown: {
    id: 'registration.town',
    defaultMessage: 'Town',
  },
  registrationPostCode: {
    id: 'registration.postcode',
    defaultMessage: 'Post code',
  },
  registrationRepresentative: {
    id: 'registration.representative',
    defaultMessage: 'Representative',
  },
  registrationRepresentativeName: {
    id: 'registration.representativeName',
    defaultMessage: 'Fullname',
  },
  registrationRepresentativeEmail: {
    id: 'registration.representativeEmail',
    defaultMessage: 'Email',
  },
  registrationRepresentativePhone1: {
    id: 'registration.representativePhone1',
    defaultMessage: 'Phone 1',
  },
  registrationRepresentativePhone2: {
    id: 'registration.representativePhone2',
    defaultMessage: 'Phone 2',
  },
  registrationInfo: {
    id: 'registration.info',
    defaultMessage: 'Info',
  },
  registrationApplication: {
    id: 'registration.application',
    defaultMessage: 'Application',
  },
  registrationReservation: {
    id: 'registration.reservation',
    defaultMessage: 'Reservation',
  },
  registrationStorno: {
    id: 'registration.storno',
    defaultMessage: 'Storno',
  },
  registrationSended: {
    id: 'registration.sended',
    defaultMessage: 'Sended',
  },
  registrationToAction: {
    id: 'registration.toAction',
    defaultMessage: 'to action',
  },
  registrationAgreement1: {
    id: 'registration.agreement1',
    defaultMessage: 'Agreement',
  },
  registrationAgreement2: {
    id: 'registration.agreement2',
    defaultMessage: 'Agreement',
  },
  registrationAgreement3: {
    id: 'registration.agreement3',
    defaultMessage: 'Agreement',
  },
  registrationReservationAgreement1: {
    id: 'registration.reservationAgreement1',
    defaultMessage: 'Agreement',
  },
  registrationReservationAgreement2: {
    id: 'registration.reservationAgreement2',
    defaultMessage: 'Agreement',
  },
  registrationSubmitAgreement: {
    id: 'registration.submitAgreement',
    defaultMessage: 'Agreement',
  },
  registrationSendReservation: {
    id: 'registration.sendReservation',
    defaultMessage: 'Send reservation',
  },
  registrationSendApplication: {
    id: 'registration.sendApplication',
    defaultMessage: 'Send application',
  },
})
