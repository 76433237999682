// @flow
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import styles from '../../../assets/jss/sar/completedStyle'

import Footer from '../../../components/Footer/Footer'
import { SarHeaderImage } from '../../../components/SarHeaderImage'
import { SarPhotos } from '../../../components/SarPhotos'

import {
  fetchAlbum,
  getAlbumInfo,
  fetchPhotos,
  getPhotos,
  unmountAlbum,
} from '../../../store/photos'

const useStyles = makeStyles(styles)

type Props = {
  match: { params: { albumId: number } },
}

export const Photoalbum = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = props

  const images = useSelector(getPhotos)
  const albumInfo = useSelector(getAlbumInfo)

  useEffect(() => {
    dispatch(fetchPhotos(match.params.albumId))
    dispatch(fetchAlbum(match.params.albumId))
  }, [dispatch, match.params.albumId])

  useEffect(() => () => dispatch(unmountAlbum()), [dispatch])

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <SarPhotos albumInfo={albumInfo} images={images} />
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
