/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getFormSyncErrors, Field, reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'

import * as _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import { Forms } from '../../../constants'
import { messages, intl } from '../../../i18n'
import message from '../../../i18n/messages'
import styles from '../../../assets/jss/sar/completedStyle'

import { SarTextField } from '../../../components/SarTextField'

import Footer from '../../../components/Footer/Footer'
import { SarHeaderImage } from '../../../components/SarHeaderImage'
import { SarFileUploader } from '../../../components/SarFileUploader'
import { SarPhotos } from '../../../components/SarPhotos'

import validate from './validate'

import {
  fetchAlbum,
  getAlbumInfo,
  getAlbumformValues,
  insertAlbum,
  updateAlbum,
  deleteAlbum,
  insertPhoto,
  startPhotoInsert,
  getLoadedPhotos,
  photosLoaded,
  getLoadedPhotosStatus,
  deleteLoadedPhoto,
  fetchPhotos,
  getPhotos,
} from '../../../store/photos'

const useStyles = makeStyles(styles)

type Props = {
  match: { params: { albumId: number } },
  touch: boolean,
  valid: boolean,
}

type CheckBoxProps = {
  input: { value: string, onChange: func },
  label: string,
}

const renderCheckbox = ({ input, label }: CheckBoxProps) => (
  <FormControlLabel
    control={
      <Checkbox color="default" checked={!!input.value} onChange={input.onChange} name={label} />
    }
    label={label}
  />
)

let AdminPhotoalbum = (props: Props) => {
  const { match, touch, valid } = props

  const classes = useStyles()

  const dispatch = useDispatch()

  const photosList = useSelector(getLoadedPhotos)
  const albumInfo = useSelector(getAlbumInfo)
  const images = useSelector(getPhotos)
  const loadedPhotosStatus = useSelector(getLoadedPhotosStatus)
  const syncErrors = useSelector(getFormSyncErrors(Forms.albumForm))
  const albumsFormValues = useSelector(getAlbumformValues)

  const [photo, setPhoto] = React.useState()
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)

  useEffect(() => {
    dispatch(fetchPhotos(match.params.albumId))
    dispatch(fetchAlbum(match.params.albumId))
    if (match.params.albumId === '0') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [dispatch, match.params.albumId])

  const handleInputChange = event => {
    dispatch(photosLoaded(_.unionBy(photosList, [...event.target.files], 'name')))
  }

  const handleChangeAlbumPhoto = event => {
    setPhoto(event.target.files[0])
  }

  const submitPhotos = () => {
    let data = new FormData()

    photosList.forEach(item => {
      data = new FormData()

      data.append('file', item)
      dispatch(insertPhoto(match.params.albumId, data, item.name))
      dispatch(startPhotoInsert())
    })
  }
  const deleteFile = fileName => {
    dispatch(deleteLoadedPhoto(fileName))
  }

  const saveAndSubmitAlbum = () => {
    if (valid) {
      const data = new FormData()
      data.append('file', photo)

      if (match.params.albumId === '0') {
        dispatch(insertAlbum(albumsFormValues, data))
      } else {
        dispatch(updateAlbum(albumsFormValues, data))
      }
    } else {
      touch(...Object.keys(syncErrors))
    }
  }

  const handleClickOpenDeleteAlbum = () => {
    setOpen(true)
  }

  const handleCloseDeleteAlbum = () => {
    setOpen(false)
  }

  const deleteThisAlbum = () => {
    if (match.params.albumId !== '0') {
      dispatch(deleteAlbum(match.params.albumId))
    }
    setOpen(false)
  }

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <SarPhotos albumInfo={albumInfo} images={images} />

          <Grid container item md={8} xs={11}>
            <form>
              <Grid container item md={12} xs={12}>
                <Grid item md={12} xs={12} className={classes.inputStyle}>
                  <Field
                    name="title"
                    component={SarTextField}
                    type="text"
                    label={intl.formatMessage(messages.title)}
                  />
                </Grid>
                <Grid item md={3} xs={6} className={classes.inputStyle}>
                  <Field
                    name="active"
                    component={renderCheckbox}
                    label={intl.formatMessage(messages.active)}
                  />
                </Grid>
                <Grid item md={6} xs={6} className={classes.inputStyle}>
                  <label htmlFor="upload-album-photo">
                    <input
                      style={{ display: 'none' }}
                      id="upload-album-photo"
                      name="albumPhoto"
                      type="file"
                      onChange={handleChangeAlbumPhoto}
                      accept="image/*"
                    />
                    <Tooltip
                      classes={{ tooltip: classes.noMaxWidth }}
                      title={<img src={albumInfo.img} alt="obr" width="300" />}>
                      <Button className={classes.button}>
                        <Avatar>
                          <img src={albumInfo.img} alt="obr" width="40" height="40" />
                        </Avatar>
                      </Button>
                    </Tooltip>

                    <Tooltip
                      classes={{ tooltip: classes.noMaxWidth }}
                      title={intl.formatMessage(messages.photoPerfectsize)}>
                      <Button color="default" variant="contained" component="span">
                        <FormattedMessage {...message.photoChoosePhoto} />
                      </Button>
                    </Tooltip>
                    {photo && (
                      <Tooltip
                        classes={{ tooltip: classes.noMaxWidth }}
                        title={<img src={URL.createObjectURL(photo)} alt="obr" width="300" />}>
                        <Button className={classes.button}>
                          <Avatar>
                            <img
                              src={URL.createObjectURL(photo)}
                              alt="obr"
                              width="40"
                              height="40"
                            />
                          </Avatar>
                        </Button>
                      </Tooltip>
                    )}
                    {photo && photo.name}
                  </label>
                </Grid>
                <Grid item md={3} xs={6} className={classes.inputStyle}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={saveAndSubmitAlbum}
                    className={`${classes.right} ${classes.margin8Top}`}>
                    <FormattedMessage {...message.send} />
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClickOpenDeleteAlbum}
                    disabled={disabled}
                    className={`${classes.right} ${classes.margin8Top} ${classes.marginRight}`}>
                    <FormattedMessage {...message.photoConfirmDeleteAlbum} />{' '}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleCloseDeleteAlbum}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                      <FormattedMessage {...message.photoConfirmDeleteAlbum} />
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <FormattedMessage {...message.photoConfirmDeleteAlbumLong} />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={deleteThisAlbum} color="primary">
                        <FormattedMessage {...message.yes} />
                      </Button>
                      <Button onClick={handleCloseDeleteAlbum} color="primary" autoFocus>
                        <FormattedMessage {...message.no} />
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item md={12} xs={12}>
                  <SarFileUploader
                    handleInputChange={handleInputChange}
                    submit={submitPhotos}
                    files={photosList}
                    deleteFile={deleteFile}
                    disabled={disabled}
                    uploaded={loadedPhotosStatus}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}

AdminPhotoalbum = reduxForm({
  form: Forms.albumForm, // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(AdminPhotoalbum)

export default AdminPhotoalbum = connect(state => ({
  initialValues: state.photos.album, // pull initial values from account reducer
}))(AdminPhotoalbum)
