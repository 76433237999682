// @flow
export default {
  'app.sar': 'Studio aktivní rekreace',
  'app.blueKangaroo': 'Blue Kangaroo',
  'app.mail': 'info@studioar.cz',
  'app.mailBK': 'bluekangaroo@bluekangaroo.info',
  'app.www': 'www.studioar.cz',
  'app.wwwBK': 'www.bluekangaroo.info',
  'app.studio': 'Studio',
  'app.vaclavMachac': 'Mgr. Václav Machač',
  'app.address': 'Adresa',
  'app.cernilov': 'Černilov 305, 503 43 Černilov',
  'app.phone': 'Telefon',
  'app.phone1': '777 006 005',
  'app.phone2': '777 004 003',
  'app.filter': 'Filtruj...',
  'app.yes': 'Ano',
  'app.no': 'Ne',
  'app.save': 'Uložit',
  'app.archive': 'Archivovat',
  'app.send': 'Odeslat',
  'app.active': 'Aktivní',
  'app.inactive': 'Neaktivní',
  'app.selected': 'Vybrané',
  'app.others': 'Ostatní',
  'app.inactiveActionList': 'Seznam neaktivních akcí',
  'app.actionList': 'Seznam akcí',
  'app.chooseActionList': 'Vyber ze seznamu akcí',
  'app.back': 'Zpět',
  'app.login': 'Přihlásit se',
  'app.required': 'Povinné pole',
  'app.urlCopied': 'URL zkopírovaná do schránky',
  'app.notShared': 'Tento dokument není sdílený',
  'app.otherInfo': 'Další informace',
  'app.rowsPerPage': 'Řádků na stránku',
  'app.separator': 'ze',
  'app.all': 'Vše',
  'app.edit': 'Editace',
  'admin.newNews': 'Vytvořit novou aktualitu',
  'validate.email': 'Neplatný email',
  'validate.phone': 'Neplatný formát telefonu',
  'app.signout': 'Odhlásit',
  'app.adminMenu': 'Admin Menu',
  'app.menu': 'Ostatní',
  'app.successfullySent': 'Úspěšně odesláno',
  'app.addFile': 'Přidat soubor',
  'app.googleDrive': 'Google drive',
  'app.title': 'Nadpis',
  'app.notFound': 'Nenalezeno',
  'app.downloadExcel': 'Stáhnout excel',
  // nadpisy
  'headers.reservation': 'Přihláška / rezervace',
  // odkazy
  'links.adminSection': 'Admin sekce',
  'links.adminNews': 'Aktuality',
  'links.news': 'Aktualita',
  'links.adminParticipants': 'Účastníci',
  'links.mainPage': 'Hlavní stránka',
  'links.photogallery': 'Fotogalerie',
  'links.adminPhotogallery': 'Fotogalerie',
  'links.adminActions': 'Akce',
  'links.conditions': 'Všeobecné podmínky',
  'links.contacts': 'Kontakty',
  'links.login': 'Login',
  'links.faqSki': 'Lyžařská a SNB škola (nejčastější otázky)',
  'links.faqBike': 'Bike (nejčastější otázky)',
  'links.faqCamp': 'Letní tábory (nejčastější otázky)',
  'actions.createNewAction': 'Založit novou akci',
  'actions.inactiveProgramList': 'Seznam neaktivních programů',
  'actions.createNewProgram': 'Založit nový program',
  'actions.inactiveTransportList': 'Seznam neaktivních doprav',
  'actions.createNewTransport': 'Založit novou dopravu',
  'news.subtitle': 'Podnadpis',
  'news.image': 'Obrázek',
  'news.chooseImage': 'Vybrat obrázek',
  'news.deleteNews': 'Smazat aktualitu',
  'news.confirmDeleteNews': 'Smazat aktualitu?',
  'news.confirmDeleteNewsLong': 'Opravdu chcete smazat tuto aktualitu?',
  // menu
  'menu.twitterLabel': 'Najdete nás na twitteru',
  'menu.twitterURL': 'https://twitter.com/BlueKangarooSAR',
  'menu.fbLabel': 'Sledujte nás na facebooku',
  'menu.fbURL': 'https://www.facebook.com/StudioAktivniRekreace/',
  'menu.instaLabel': 'Jsme také na instagramu',
  'menu.instaURL': 'https://www.instagram.com/bluekangaroo_sar/',
  'photo.choosePhoto': 'Vybrat fotografii',
  'photo.perfectSize': 'Ideální rozměry fotografie jsou 720px × 480px',
  'photo.confirmDeleteAlbum': 'Smazat album',
  'photo.confirmDeleteAlbumLong': 'Opravdu chcete smazat toto fotoalbum?',
  // registration
  'registration.action': 'Akce',
  'registration.state': 'Stav',
  'registration.changeState': 'Změnit stav',
  'registration.program': 'Program',
  'registration.programs': 'Programy',
  'registration.transport': 'Doprava',
  'registration.departure': 'Místo odjezdu',
  'registration.arrival': 'Místo příjezdu',
  'registration.participant': 'Účastník',
  'registration.name': 'Jméno',
  'registration.surname': 'Příjmení',
  'registration.birthnumber': 'Rodné číslo',
  'registration.address': 'Adresa',
  'registration.street': 'Ulice',
  'registration.houseNumber': 'Číslo popisné',
  'registration.town': 'Město',
  'registration.postcode': 'PSČ',
  'registration.representative': 'Zákonný zástupce',
  'registration.representativeName': 'Jméno + Příjmení',
  'registration.representativeEmail': 'Email',
  'registration.representativePhone1': 'Telefon',
  'registration.representativePhone2': 'Druhý telefon',
  'registration.info': 'Další sdělení',
  'registration.reservation': 'Rezervace',
  'registration.application': 'Přihláška',
  'registration.storno': 'Storno',
  'registration.sended': 'Odeslaná',
  'registration.toAction': 'na akci',
  'registration.agreement1':
    'Dávám výslovně souhlas s tím, aby Václav Machač, IČ 62687999, (dále jen „Správce“) zpracovával v souladu s nařízením Evropského parlamentu a Rady EU 2016/679 ze dne 27.4.2016 o Ochraně fyzických údajů a volném pohybu těchto údajů osobní údaje dítěte a zákonných zástupců dítěte za účelem vedení evidence a s tím souvisejícími činnostmi. Beru na vědomí, že správce předává osobní údaje Studiu aktivní rekreace s.r.o., IČ 27490807.',
  'registration.agreement2':
    'Souhlasím, aby Správce zpracovával fotografie, videa a zvukové záznamy účastníka akce za účelem prezentace na webu a sociálních sítích.',
  'registration.agreement3':
    'Jsem informován, že na základě oprávněného zájmu Správce budou Osobní údaje zpracovávány a uchovávány po dobu 10 let ode dne ukončení akce, na kterou je dítě přihlášeno. Jsem srozuměn se svým právem mít přístup ke svým osobním údajům, požadovat jejich opravu a odvolat tento Souhlas.',
  'registration.reservationAgreement1': 'Podmínky rezervace: ',
  'registration.reservationAgreement2':
    'Místo na akci Vám bude rezervováno do okamžiku, dokud nebudete blokovat možnost řádného přihlášení dalším zájemcům o akci. O tomto budete vyrozuměni e-mailem, případně jinou formou (pomocí SMS, telefonicky). Do sedmi dnů máte možnost změnit Rezervaci na Přihlášku. Po uplynutí této lhůty rezervace automaticky zaniká. Rezervace také automaticky zaniká sedm dnů před započetím akce.',
  'registration.submitAgreement':
    'Jsem seznámen se všeobecnými podmínkami v plném rozsahu a souhlasím s nimi.',
  'registration.sendReservation': 'Odeslat rezervaci',
  'registration.sendApplication': 'Odeslat přihlášku',
}
