// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MuButton from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import Menu from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Apps } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import { ROUTES } from '../../constants'

import { messages, intl } from '../../i18n'
import Button from '../CustomButtons/Button'
import CustomDropdown from '../CustomDropdown/CustomDropdown'

import { signOut } from '../../store/authorization'

import styles from '../../assets/jss/material-kit-react/components/headerStyle'
import styles2 from '../../assets/jss/sar/completedStyle'

const useStyles = makeStyles(styles)
const useStyles2 = makeStyles(styles2)

type Props = {
  color: string,
  // rightLinks: array,
  leftLinks: array,
  fixed: boolean,
  absolute: boolean,
  changeColorOnScroll: boolean,
  opened: boolean,
  menuLinks: array,
  dropdownLinks: array,
  socialLinks: array,
  signingOut: boolean,
}

export const Header = (props: Props) => {
  const {
    color,
    leftLinks,
    fixed,
    absolute,
    changeColorOnScroll,
    menuLinks,
    dropdownLinks,
    socialLinks,
    signingOut,
  } = props
  const dispatch = useDispatch()

  const classes = useStyles()
  const classes2 = useStyles2()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const onSignOutClick = event => {
    dispatch(signOut())
    setMobileOpen(false)
    event.preventDefault()
  }

  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange)
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange)
      }
    }
  })

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color])
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[changeColorOnScroll.color])
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color])
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[changeColorOnScroll.color])
    }
  }

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  })
  const brandComponent = (
    <MuButton className={classes.title}>
      <Link to="/" className={classes.title}>
        <FormattedMessage {...messages.sar} />
      </Link>
    </MuButton>
  )
  const dropdownList =
    dropdownLinks &&
    dropdownLinks.map(value => {
      return (
        <ListItem key={value.id} className={classes2.headerListItem}>
          <Link to={value.link} className={classes2.headerListItem}>
            <Button
              color="transparent"
              target="_blank"
              style={{ padding: '12px', textAlign: 'left' }}
              onClick={() => setMobileOpen(false)}>
              <FormattedMessage {...value.message} />
            </Button>
          </Link>
        </ListItem>
      )
    })

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes2.appBar}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden smDown implementation="css">
          {/* {rightLinks} */}
          <List className={classes2.headerList}>
            {menuLinks &&
              menuLinks.map(value => {
                return (
                  <ListItem key={value.id} className={classes2.headerListItem}>
                    <Link to={value.link} className={classes2.headerListItem}>
                      <Button
                        color="transparent"
                        target="_blank"
                        style={{ padding: '12px' }}
                        onClick={() => setMobileOpen(false)}>
                        {value.editIcon && <EditIcon className={classes.icons} />}
                        <FormattedMessage {...value.message} />
                      </Button>
                    </Link>
                  </ListItem>
                )
              })}
            {signingOut && (
              <ListItem key={99} className={classes2.headerListItem}>
                <Link to={ROUTES.ADMIN_NEWS} className={classes2.headerListItem}>
                  <Button
                    color="transparent"
                    target="_blank"
                    style={{ padding: '13px' }}
                    onClick={onSignOutClick}>
                    <FormattedMessage {...messages.signout} />
                  </Button>
                </Link>
              </ListItem>
            )}
            {dropdownLinks && (
              <ListItem className={classes2.headerListItem}>
                <CustomDropdown
                  noLiPadding
                  buttonText={intl.formatMessage(messages.menu)}
                  buttonProps={{
                    className: classes.navLink,
                    color: 'transparent',
                  }}
                  buttonIcon={Apps}
                  dropdownList={dropdownList}
                />
              </ListItem>
            )}

            {socialLinks &&
              socialLinks.map(value => {
                return (
                  <ListItem key={value.id} className={classes2.headerListItem}>
                    <Tooltip
                      id="instagram-tooltip"
                      title={intl.formatMessage(value.message)}
                      placement={window.innerWidth > 959 ? 'top' : 'left'}
                      classes={{ tooltip: classes.tooltip }}>
                      <Button
                        color="transparent"
                        target="_blank"
                        href={intl.formatMessage(value.link)}
                        style={{ padding: '12px', textAlign: 'left !important' }}
                        onClick={() => setMobileOpen(false)}>
                        <i className={`${classes2.socialIcons} ${value.img}`} />
                      </Button>
                    </Tooltip>
                  </ListItem>
                )
              })}
          </List>
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" aria-label="open drawer" onClick={() => setMobileOpen(true)}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={() => setMobileOpen(false)}>
          <div className={classes.appResponsive}>
            {/* {leftLinks} */}
            {/* {rightLinks} */}
            <List className={classes2.headerList}>
              {menuLinks &&
                menuLinks.map(value => {
                  return (
                    <ListItem
                      key={value.id}
                      className={classes2.headerListItem}
                      onClick={() => setMobileOpen(false)}>
                      <Link to={value.link} className={classes2.headerListItem}>
                        <Button color="transparent" target="_blank" style={{ padding: '12px' }}>
                          {value.editIcon && <EditIcon className={classes.icons} />}
                          <FormattedMessage {...value.message} />
                        </Button>
                      </Link>
                    </ListItem>
                  )
                })}
              {signingOut && (
                <ListItem key={99} className={classes2.headerListItem}>
                  <Link to={ROUTES.ADMIN_NEWS} className={classes2.headerListItem}>
                    <Button
                      color="transparent"
                      target="_blank"
                      style={{ padding: '13px' }}
                      onClick={onSignOutClick}>
                      <FormattedMessage {...messages.signout} />
                    </Button>
                  </Link>
                </ListItem>
              )}
              {dropdownLinks &&
                dropdownLinks.map(value => {
                  return (
                    <ListItem
                      key={value.id}
                      className={classes2.headerListItem}
                      onClick={() => setMobileOpen(false)}>
                      <Link to={value.link} className={classes2.headerListItem}>
                        <Button
                          color="transparent"
                          target="_blank"
                          style={{ padding: '12px', textAlign: 'left' }}>
                          <FormattedMessage {...value.message} />
                        </Button>
                      </Link>
                    </ListItem>
                  )
                })}
              {socialLinks &&
                socialLinks.map(value => {
                  return (
                    <ListItem
                      key={value.id}
                      className={classes2.headerListItem}
                      onClick={() => setMobileOpen(false)}>
                      {/* <Link to={value.link} className={classes2.listItem}> */}
                      <Button
                        color="transparent"
                        target="_blank"
                        href={intl.formatMessage(value.link)}
                        style={{ padding: '12px', textAlign: 'left !important' }}>
                        <i className={`${classes2.socialIcons} ${value.img}`} />{' '}
                        <FormattedMessage {...value.message} />
                      </Button>
                      {/* </Link> */}
                    </ListItem>
                  )
                })}
            </List>
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  )
}

Header.defaultProp = {
  color: 'white',
}

Header.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,

  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'transparent',
      'white',
      'rose',
      'dark',
    ]).isRequired,
  }),
}
