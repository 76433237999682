// @flow
import { getFormValues } from 'redux-form'
import * as _ from 'lodash'
import Forms from '../../constants/forms'

// export const getAlbums = state => state.photos.albums

// export const getActiveAlbums = (state: StateT) => getAlbums(state).filter(item => item.active)

export const getAlbumInfo = state => state.photos.album

export const getAlbumformValues = (state: State): ?Object => getFormValues(Forms.albumForm)(state)

export const getLoadedPhotos = state => state.photos.loadedPhotos

export const getLoadedPhotosStatus = state => state.photos.loadPhotosActive

export const getPhotos = state => state.photos.photos

// export const getAllAlbums = state => state.photos.albums

export const getAllAlbums = (state: StateT) => _.get(state, ['photos'], {})

export const getAlbums = (state: StateT) =>
  _.orderBy(_.get(getAllAlbums(state), ['albums'], {}), 'priority', 'desc')

export const getActiveAlbums = (state: StateT) => getAlbums(state).filter(item => item.active)
