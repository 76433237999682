// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { HashLink } from 'react-router-hash-link'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { ROUTES } from '../../constants'
import styles from '../../assets/jss/sar/completedStyle'
import message from '../../i18n/messages'

import Footer from '../../components/Footer/Footer'
import { SarHeaderImage } from '../../components/SarHeaderImage'

const useStyles = makeStyles(styles)

export const Conditions = (props: Props) => {
  const classes = useStyles()

  return (
    <div>
      <SarHeaderImage />

      <div className={classes.raisedMain}>
        <Grid container justify="center">
          <Grid item md={8} xs={11}>
            <Grid item md={12} className={classes.center}>
              <h1>Všeobecné podmínky</h1>
              <img alt="klokan" src={`${process.env.PUBLIC_URL}/imgs/klokanBlack.png`} />
              <h2>Pro účast na akcích Studia aktivní rekreace</h2>
            </Grid>

            <Grid item md={12}>
              <p>
                <HashLink to="#prihlaseni">1. Přihlášení na akci</HashLink>
                <br />
                <HashLink to="#rezervace">2. Rezervace</HashLink>
                <br />
                <HashLink to="#uhrada">3. Způsob úhrady</HashLink>
                <br />
                <HashLink to="#zmena">4. Změna smlouvy</HashLink>
                <br />
                <HashLink to="#zmenalyze">
                  5. Změna smlouvy při pořádání Lyžařské a Snowboardové školy
                </HashLink>
                <br />
                <HashLink to="#prohlaseni">
                  6. Prohlášení zákonných zástupců účastníků Lyž. a SNB školy
                </HashLink>
                <br />
                <HashLink to="#storno">7. Stornovací podmínky</HashLink>
                <br />
                <HashLink to="#informace">8. Informace o akci</HashLink>
                <br />
                <HashLink to="#pojistne">9. Pojistné podmínky</HashLink>
                <br />
                <span id="prihlaseni"> </span>
                <HashLink to="#osetreni">
                  10. Poplatky za ošetření ve zdravotnických zařízeních, recepty, léky
                </HashLink>
                <br />
                <HashLink to="#kontakt">11. Kontaktní místa</HashLink>
                <br />
                <HashLink to="#vseob">12. Všeobecná ustanovení</HashLink>
                <br />
              </p>

              <h3>1. Přihlášení na akci</h3>
              <span id="rezervace"> </span>

              <p>
                <strong>1.1.</strong>&nbsp;V případě zájmu o účast na akci zašlete řádně vyplněnou
                přihlášku jakoukoli formou (pošta, fax, e-mail) na naši adresu nebo odešlete
                elektronickou přihlášku z našich webových stránek.
                <br />
                <strong>1.2.</strong>&nbsp;Zařazení (nezařazení) na akci bude účastníkovi sděleno
                ihned po obdržení přihlášky.
                <br />
                <strong>1.3.</strong>&nbsp;Zrušení účasti na akci Studia je zákazník povinen provést
                písemně. Při odstoupení od smlouvy je zákazník povinen splnit závazky vyplývající z
                bodu 7.
                <br />
                <strong>1.4.</strong>&nbsp;V případě hromadné přihlášky je součástí této přihlášky
                seznam účastníků akce.
              </p>

              <h3>2. Rezervace</h3>
              <span id="uhrada"> </span>

              <p>
                <strong>2.1.</strong>&nbsp;V případě zájmu o účast na akci můžete také zaslat řádně
                vyplněnou rezervaci jakoukoli formou (pošta, fax, e-mail) na naši adresu, nebo
                odeslat elektronickou rezervaci z našich webových stránek.
                <br />
                <strong>2.2.</strong>&nbsp;Přijetí (nepřijetí) rezervace Vám bude sděleno ihned po
                jejím obdržení.
                <br />
                <strong>2.3.</strong>&nbsp;Místo na akci Vám bude rezervováno do okamžiku, dokud
                nebudete blokovat možnost řádného přihlášení dalším zájemcům o akci. O tomto budete
                vyrozuměni e-mailem, případně jinou formou (pomocí SMS, telefonicky). Poté máte
                možnost do sedmi dnů změnit Rezervaci na Přihlášku. Po uplynutí této lhůty rezervace
                automaticky zaniká.&nbsp;
                <br />
                <strong>2.4.</strong>&nbsp;Rezervace také zaniká sedm dnů před započetím akce.
              </p>

              <h3>3. Způsob úhrady</h3>
              <span id="zmena"> </span>

              <p>
                <strong>3.1.</strong>&nbsp;úhradu akce proveďte po oznámení o zařazení účastníka na
                akci, nejdéle však 30 dnů před započetím akce.
                <br />
                <strong>3.2.</strong>&nbsp;U některých akcí mohou být lhůty uhrazení stanoveny
                jinak.
                <br />
                <strong>3.3.</strong>&nbsp;úhradu proveďte převodem na účet, který je uvedený v
                pokynech k úhradě konkrétní akce. Jako variabilní symbol uveďte rodné číslo
                účastníka akce.
                <br />
                <strong>3.4.</strong>&nbsp;U hromadných přihlášek je způsob a termín úhrady určen po
                domluvě mezi zákazníkem a Studiem aktivní rekreace.
              </p>

              <h3>4. Změna smlouvy</h3>
              <span id="zmenalyze"> </span>

              <p>
                <strong>4.1.</strong>&nbsp;Smlouvou se rozumí nabídkový materiál týkající se dané
                akce, který zahrnuje nabízené služby a přihlášku obsahující výňatek ze všeobecných
                podmínek platných pro účast na akcích pořádaných Studiem.
                <br />
                <strong>4.2.</strong>&nbsp;Provede-li Studio před prvním poskytnutím služeb
                podstatnou změnu v kvalitě nebo v ceně akce o více než 10%, je povinno tuto
                skutečnost zákazníkovi neprodleně ohlásit a vyžádat si ve stanovené lhůtě jeho
                souhlas s provedenou změnou. Jestliže zákazník se změnou nesouhlasí, má právo od
                smlouvy odstoupit. Při dodržení stanovené lhůty se tak stane bez povinnosti jakékoli
                náhrady vůči Studiu a Studio vrátí zákazníkovi zaplacenou cenu služeb.&nbsp;
                <br />
                <strong>4.3.</strong>&nbsp;Dojde-li ze strany Studia ke zrušení akce, Studio
                zákazníkovi tuto skutečnost neprodleně oznámí a vrátí mu zaplacenou cenu služeb.
              </p>

              <h3>5. Změna smlouvy při pořádání Lyžařské a Snowboardové školy</h3>
              <span id="prohlaseni"> </span>

              <p>Pro účast na Lyžařské a Snowboardové škole platí dále tyto podmínky:</p>

              <p>
                <strong>5.1.</strong>&nbsp;V případě špatných sněhových podmínek může Lyžařská a
                Snowboardová škola změnit termín konání.
                <br />
                <strong>5.2.</strong>&nbsp;V případě, že se v místě konání Lyžařské a Snowboardové
                školy prudce změní počasí (vánice, silný déšť apod.) může Lyžařská a Snowboardová
                škola zvolit náhradní program.
                <br />
                <strong>5.3.</strong>&nbsp;V případě, že počasí nedovolí absolvovat účastníkům
                Lyžařské a Snowboardové školy všechny zájezdy kurzu v dané sez&oacute;ně, přesune se
                kurzovné do další sez&oacute;ny.
              </p>

              <p>
                <strong>5.4.</strong>&nbsp;V případě, že nařízením vlády nebude možné dodržet
                plánované termíny Lyžařské a Snowbordové školy, ale bude možné ji absolvovat v
                jiných termínech téže sez&oacute;ny, může Lyžařská a Snowboardová škola tyto termíny
                změnit.
              </p>

              <h3>6. Prohlášení zákonných zástupců účastníků Lyžařské a Snowboardové školy</h3>
              <span id="storno"> </span>

              <p>
                <strong>6.1.</strong>&nbsp;Zákonní zástupci se zavazují, že lyžařské bezpečnostní
                vázání dítěte bude odborně zkontrolováno a seřízeno.
                <br />
                <strong>6.2.</strong>&nbsp;Zákonní zástupci prohlašují, že přihlášený účastník
                Lyžařské a Snowboardové školy je psychicky a fyzicky schopen absolvovat výcvik ve
                sjezdovém lyžování nebo snowboardingu.
              </p>

              <h3>7. Stornovací podmínky</h3>
              <span id="informace"> </span>

              <p>
                <strong>7.1.</strong>&nbsp;Odstoupí-li zákazník od smlouvy, je povinen uhradit
                Studiu účelně vynaložené náklady, které v důsledku toho vznikly (pokud se nejedná o
                skutečnosti vyplývající z bodu 4.).
                <br />
                <strong>7.2.</strong>&nbsp;Nejméně však u zrušení účasti jednotlivcem :<br />
                - do 30-ti dnů před stanoveným termínem odjezdu 30 %<br />
                - 29 - 21 dnů před odjezdem 50 % z ceny akce
                <br />
                - 20 - 11 dnů před odjezdem 70 % z ceny akce
                <br />
                - 10 a méně dnů před odjezdem 100 % z ceny akce
                <br />
                <strong>7.3.</strong>&nbsp;Nejméně však u zrušení hromadné přihlášky :<br />
                - do 30-ti dnů před započetím akce 70 % z ceny akce
                <br />
                - 30 a méně dnů 100% z ceny akce
                <br />
                <strong>7.4.</strong>&nbsp;Studio není povinno poskytnout zákazníkovi náhradu ani
                slevu, jestliže zákazník sjednaných služeb nevyužije.
                <br />
                <strong>7.5.</strong>&nbsp;U některých akcí může být výše stornopoplatků stanovena
                jinak.
              </p>

              <h3>8. Informace o akci</h3>
              <span id="pojistne"> </span>

              <p>
                <strong>8.1.&nbsp;</strong>účastníci akce obdrží podrobné informace nejdéle 30 dnů
                před započetím této akce.
                <br />
                <strong>8.2.&nbsp;</strong>U některých akcí může být termín podání informací
                stanoven jinak.
              </p>

              <h3>9. Pojistné podmínky</h3>
              <span id="osetreni"> </span>

              <p>
                Druh pojištění účastníků se u jednotlivých akcí liší. Pro bližší informace
                kontaktujte{' '}
                <Link to={ROUTES.CONTACTS}>
                  <FormattedMessage {...message.studio} />
                </Link>
                .
              </p>

              <h3>10. Poplatky za ošetření ve zdravotnických zařízeních, recepty, léky</h3>
              <span id="kontakt"> </span>

              <p>
                <strong>10.1.</strong>&nbsp;Poplatky za recept, návštěvu pohotovosti, klinické
                ošetření lékařem, léky apod. jdou na vrub účastníka akce, respektive zákonných
                zástupců účastníka akce.
              </p>

              <h3>11. Kontaktní místa</h3>
              <span id="vseob"> </span>

              <p>
                <strong>11.1.</strong>&nbsp;Kontaktní místa jsou uvedena na všech propagačních
                materiálech vydaných Studiem.
                <br />
                <strong>11.2.</strong>&nbsp;Osobami oprávněnými sjednávat služby jménem Studia jsou:
                Mgr. Václav Machač, Růžena Machačová a případně další osoby uvedené v propagačních
                materiálech.
              </p>

              <h3>12. Všeobecná ustanovení</h3>

              <p>
                <strong>12.1.</strong>&nbsp;Podpisem závazné přihlášky nebo rezervace zákazník
                potvrzuje, že všeobecné podmínky platné pro účast na akcích pořádaných Studiem
                aktivní rekreace jsou mu známy, souhlasí s nimi a přijímá je v plném rozsahu.
                <br />
                <strong>12.2.</strong>&nbsp;Souhlas se všeobecnými podmínkami dává zákazník také
                odesláním elektronické přihlášky nebo rezervace, případně telefonickým přihlášením
                nebo telefonickou rezervací a úhradou zálohy či celou platbou za akci.
                <br />
                <strong>12.3.</strong>&nbsp;Zákazník dává výslovně souhlas s tím, aby Václav Machač,
                IČ: 62687999 a Studio aktivní rekreace s.r.o., IČ: 27490807, zpracovávali v souladu
                se zákonem č. 101/2000 Sb. O ochraně osobních údajů osobní údaje zákazníka. Václav
                Machač a Studio aktivní rekreace s.r.o. mohou používat tyto údaje pouze v rozsahu
                nutném pro svoji činnost. Souhlas uděluje zákazník na dobu neurčitou. Odvolání
                tohoto souhlasu může zákazník učinit kdykoli písemnou formou. Poté jsou Václav
                Machač a Studio aktivní rekrace s.r.o. povinni údaje do jednoho roku vyřadit z
                evidence a zlikvidovat.
                <br />
                <strong>12.4.</strong>&nbsp;Pro školy v přírodě, lyžařské kurzy a podobné akce jsou
                tyto všeobecné podmínky upraveny a budou konzultovány přímo se zákazníkem.
                <br />
                <strong>12.5.</strong>&nbsp;Nesouhlas s některou části těchto podmínek můžete
                projednat se zástupci Studia (viz bod 11.2.).
              </p>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  )
}
